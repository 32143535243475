<template>
    <div>
        <el-row>
            <el-col :span="12">
                <div style="padding: 20px">
                    <h1 style="font-size: 200%">围手术期管理系统</h1>
                    <p>围手术期管理软件，功能包括手术预约、手术记录、</p>
                    <p>预诊、患者术前教育、自助打印《知情同意书》。</p>
                    <p>
                        <a href="/docs/index.html" target="_blank">API 接口文档</a>
                    </p>
                </div>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <img src="../../assets/hero.png" width="500px"/>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                count: {}
            }
        }
    }
</script>

<style scoped>
</style>
